import { Component, OnInit, inject } from '@angular/core';

import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { BaseComponent } from 'src/app/shared/base-component';
import { State } from 'src/app/shared/state';
import { CommingSoonComponent } from "../../../shared/comming-soon/comming-soon.component";
import { ApiService } from '@core/api/api.service';
import { GetSubTopic, GetSubTopicListResponse } from '@core/api/model';

@Component({
  selector: 'app-tab',
  standalone: true,
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
  imports: [RouterModule, RouterOutlet, CommingSoonComponent]
})
export class TabComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  activatedRoute = inject(ActivatedRoute);

  activeTab: string = 'import';
  id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';


  nonTarrifList = new State<GetSubTopicListResponse>();
  tarrifList = new State<GetSubTopicListResponse>();
  exportTemporary = new State<GetSubTopicListResponse>();
  incentivesAndSubsidies = new State<GetSubTopicListResponse>();

  restrictions = new State<GetSubTopicListResponse>();
  serviceRestrictiveness = new State<GetSubTopicListResponse>();
  fdi = new State<GetSubTopicListResponse>();
  exportRates = new State<GetSubTopicListResponse>();

  ngOnInit(): void {

    this.executeRequest<GetSubTopicListResponse>({
      state: this.nonTarrifList,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'NON_TARRIF'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.tarrifList,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'TARRIF'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.exportTemporary,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'EXPORT_TEMPORARY'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.incentivesAndSubsidies,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'INCENTIVES_AND_SUBSIDIES'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.restrictions,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'RESTRICTIONS'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.serviceRestrictiveness,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'SERVICE_RESTRICTIVENESS'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.exportRates,
      asyncResource: this.apiService.getSubTopicList(
        {
          pageNumber: 1,
          pageSize: 50,
          topicId: 'EXPORT_RATES'
        }
      ),
    });

    this.executeRequest<GetSubTopicListResponse>({
      state: this.fdi,
      asyncResource: this.apiService.getSubTopicList({
        pageNumber: 1,
        pageSize: 50,
        topicId: 'FDI'
      }),
    });
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }



  navToSummary(item: GetSubTopic) {
    this.router.navigate([`summary/${this.id}/${item.id}`,]);
  }


}
