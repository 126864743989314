<section class="text-background-600 body-font ">
  <ng-content></ng-content>

  <div class="container md:px-5 md:py-5 mx-auto flex flex-wrap flex-col">
    <div class="flex mx-auto flex-wrap mb-6 ">
      <a (click)="setActiveTab('import')"
        [class]="activeTab === 'import' ? 'border-primary-500 text-primary-500' : 'bg-background-100'"
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium cursor-pointer no-underline hover:underline  inline-flex items-center leading-none  tracking-wider rounded-t">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          class="w-5 h-5 mr-3" viewBox="0 0 24 24">
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </svg>Import
      </a>
      <a (click)="setActiveTab('export')"
        [class]="activeTab === 'export' ? 'border-primary-500 text-primary-500' : 'bg-background-100'"
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none cursor-pointer no-underline hover:underline  tracking-wider">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          class="w-5 h-5 mr-3" viewBox="0 0 24 24">
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
        </svg>Export
      </a>
      <a (click)="setActiveTab('restrictiveness')"
        [class]="activeTab === 'restrictiveness' ? 'border-primary-500 text-primary-500' : 'bg-background-100'"
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none cursor-pointer no-underline hover:underline  tracking-wider">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          class="w-5 h-5 mr-3" viewBox="0 0 24 24">
          <circle cx="12" cy="5" r="3"></circle>
          <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
        </svg>Service
      </a>
      <a (click)="setActiveTab('fdi')"
        [class]="activeTab === 'fdi' ? 'border-primary-500 text-primary-500' : 'bg-background-100'"
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none cursor-pointer no-underline hover:underline  tracking-wider">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          class="w-5 h-5 mr-3" viewBox="0 0 24 24">
          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>FDI
      </a>
    </div>

    @if (activeTab === 'import') {
    <div class="bg-background-A100 ">
      <div class="bg-background-50 rounded-lg p-6 mb-8 backdrop-blur-md">
        <div class="mb-4">
          <h3 class="text-xl font-semibold text-center text-secondary-500">Permanent</h3>
          <div class="flex justify-between">
            <div class="w-1/2">
              <h3
                class="text-lg  text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 ">
                Tariff</h3>
              <ul class="list-disc pl-6 text-left">
                @for (item of tarrifList.data()?.items; track item) {
                <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                  (click)="navToSummary(item)">{{item.name}}</li>
                }
              </ul>
            </div>
            <div class="w-1/2">
              <h3
                class="text-lg  text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 ">
                Non-Tariff</h3>
              <ul class="list-disc pl-6 text-left">
                @for (item of nonTarrifList.data()?.items; track item) {
                <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                  routerLink="/comming-soon">{{item.name}}</li>
                }
              </ul>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <h3
            class="text-xl text-center font-semibold text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 ">
            Temporary</h3>
          <ul class="list-disc pl-6 text-left">
            @for (item of exportTemporary.data()?.items; track item) {
            <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
              (click)="navToSummary(item)">{{item.name}}</li>
            } @empty {
            <app-comming-soon></app-comming-soon>
            }
          </ul>
        </div>
      </div>
    </div>
    }

    <!-- Content for the Export tab -->
    @if (activeTab === 'export') {
    <div>
      <div class="bg-background-A100">
        <div class="bg-background-50 rounded-lg shadow-lg p-6 mb-8">
          <div class="mb-4">
            <h3
              class="text-xl font-semibold text-center text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 ">
              Permanent</h3>
            <div class="flex justify-between">
              <div class="w-1/2">
                <h3
                  class="text-lg  text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 ">
                  Incentives and Subsidies</h3>
                <ul class="list-disc pl-6 text-left">
                  @for (item of incentivesAndSubsidies.data()?.items; track item) {
                  <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                    (click)="navToSummary(item)">{{item.name}}</li>
                  }
                </ul>
              </div>
              <div class="w-1/2">
                <h3 class="text-lg  text-secondary-500" routerLink="/comming-soon">Restrictions</h3>
                <ul class="list-disc pl-6 text-left">
                  @for (item of restrictions.data()?.items; track item) {
                  <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                    (click)="navToSummary(item)">{{item.name}}</li>
                  }
                </ul>
              </div>
              <div class="w-1/2">
                <h3 class="text-lg  text-secondary-500" routerLink="/comming-soon">Export Duty Rates</h3>
                <ul class="list-disc pl-6 text-left">
                  @for (item of exportRates.data()?.items; track item) {
                  <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                    (click)="navToSummary(item)">{{item.name}}</li>
                  } @empty {
                  <app-comming-soon></app-comming-soon>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

    <!-- Content for the Service Restrictiveness tab -->
    @if (activeTab === 'restrictiveness') {
    <div>
      <div class="bg-background-A100">
        <div class="bg-background-50 rounded-lg shadow-lg p-6 mb-8">
          <div class="mb-4">
            <div class="flex justify-between">
              <div class="w-1/2">
                <h3 class="text-lg  text-secondary-500">Service Restrictiveness</h3>
                <ul class="list-disc pl-6 text-left">
                  @for (item of serviceRestrictiveness.data()?.items; track item) {
                  <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                    (click)="navToSummary(item)">{{item.name}}</li>
                  } @empty {
                  <app-comming-soon></app-comming-soon>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

    <!-- Content for the FDI tab -->
    @if (activeTab === 'fdi') {
    <div>
      <div class="bg-background-A100">
        <div class="bg-background-50 rounded-lg shadow-lg p-6 mb-8">
          <div class="mb-4">
            <div class="flex justify-between">
              <div class="w-1/2">
                <h3
                  class="text-lg  text-secondary-500 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                  routerLink="/comming-soon">FDI</h3>
                <ul class="list-disc pl-6 text-left">
                  @for (item of fdi.data()?.items; track item) {
                  <li class="mb-2 cursor-pointer no-underline hover:underline hover:text-primary-700 "
                    (click)="navToSummary(item)">{{item.name}}</li>
                  } @empty {
                  <app-comming-soon></app-comming-soon>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }

  </div>
</section>
<!-- Content for the Import tab -->